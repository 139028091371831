































































































import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { SelectItem } from '../../../../dsl/atoms/Select'
import { StructureConfigurable } from '../../../../support/mixins'

import { InputMaskMixin } from '../../../shared/mixins/inputmask.mixin'
import { InputField } from '../../../shared/services/inputmask/config'

import {
  ChangeAddressFormFieldsData,
  ChangeAddressFormFieldsProps
} from './ChangeAddressFormFields.contracts'
import {
  CHANGE_ADDRESS_FORM_FIELDS_CONFIG_MAP,
  CHANGE_ADDRESS_FORM_FIELDS_KEY,
  ChangeAddressFormFieldsConfig
} from './ChangeAddressFormFields.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ChangeAddressFormFields>({
  name: 'ChangeAddressFormFields',
  created () {
    this.config = this.getComponentConfig(CHANGE_ADDRESS_FORM_FIELDS_KEY, { ...CHANGE_ADDRESS_FORM_FIELDS_CONFIG_MAP })
    this.model = { ...this.formData }
  },
  mounted (): void {
    if (this.shouldMaskPostalCode) {
      /**
       * @inheritDoc
       */
      this.maskInputField(
        this.formData.countryCode,
        InputField.PostalCode,
        this.postalCodeRef.$el.querySelector('input')
      )
    }
    if (this.shouldMaskPostalCode) {
      /**
       * @inheritDoc
       */
      this.maskInputField(
        'PL',
        InputField.Phone,
        this.phoneRef.$el?.querySelector('input')
      )
    }
  }
})
export class ChangeAddressFormFields extends Mixins(InputMaskMixin, StructureConfigurable) {
  @Prop({ type: Array, required: true })
  public countries!: ChangeAddressFormFieldsProps['countries']

  @Prop({ type: Object, required: false, default: () => ({}) })
  public errors!: ChangeAddressFormFieldsProps['errors']

  @Prop({ type: Object, required: true })
  protected readonly formData!: ChangeAddressFormFieldsData

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCompany?: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly isEditable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly noPerson!: boolean

  protected config!: ChangeAddressFormFieldsConfig

  public model: ChangeAddressFormFieldsData = {} as ChangeAddressFormFieldsData

  @Ref('postalCode')
  public postalCodeRef!: Vue

  @Ref('phoneRef')
  public phoneRef!: Vue

  public get shouldHaveCountryForm (): boolean {
    return this.getConfigProperty<boolean>('shouldHaveCountryForm')
  }

  public get shouldChangePostalCodeLayout (): boolean {
    return this.getConfigProperty<boolean>('shouldChangePostalCodeLayout')
  }

  public get shouldMaskPostalCode (): boolean {
    return this.getConfigProperty<boolean>('shouldMaskPostalCode')
  }

  /**
   * Gets error for specified fields.
   */
  public getErrors (key: string): string[] | undefined {
    if (!this.errors || !this.errors[key]) {
      return
    }

    return this.errors[key].map(e => String(this.$t(e)))
  }

  public get countriesOptions (): SelectItem[] {
    return this.countries.map((country) => {
      return {
        label: country.label,
        value: country.code
      }
    })
  }

  @Watch('model', { deep: true })
  protected onModelChange (value: ChangeAddressFormFieldsData): void {
    this.$emit('update:errors')
    this.$emit('update:formData', value)
  }
}

export default ChangeAddressFormFields
