/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum DataEntityType {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Name = 'name',
  NewPassword = 'newPassword',
  Password = 'password',
  Phone = 'phoneNumber',
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum FormInputType {
  Text = 'UiInput',
  Password = 'UiPasswordInput'
}
