
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { ChangeAddressFormFieldsProps } from '../ChangeAddressFormFields'
import { Inject } from '../../../../support'
import { IProfileService, ProfileServiceType } from '../../contracts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<SavedAddressesFormFields>({
  name: 'SavedAddressesFormFields'
})
export class SavedAddressesFormFields extends Vue {
  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

@Prop({ type: Object, required: true })
  public addresses!: ChangeAddressFormFieldsProps[]

  public selectedItem: any = ''

  public editAddress (address: any): any {
    this.$emit('triggerEditing')
    this.$emit('updateAddress', address)
  }

  public setDefault (address: any): Promise<number> {
    return this.profileService.updateDeliveryAddress(address.id, address)
  }
}
export default SavedAddressesFormFields
