






































import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProfileWrapper } from '../../../molecules/ProfileWrapper'

import { ChangeAddressForm } from '../../../organisms/ChangeAddressForm'
import { ChangeEmailForm } from '../../../organisms/ChangeEmailForm'
import { ChangeNewsletterPreferencesForm } from '../../../organisms/ChangeNewsletterPreferencesForm'
import { ChangePasswordForm } from '../../../organisms/ChangePasswordForm'
import { ChangeUserDetailsForm } from '../../../organisms/ChangeUserDetailsForm'
import { UserData } from '../../../../auth/shared'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<Default>({
  name: 'Default',
  components: {
    ChangeAddressForm,
    ChangeEmailForm,
    ChangeNewsletterPreferencesForm,
    ChangePasswordForm,
    ChangeUserDetailsForm,
    ProfileWrapper
  }
})
export class Default extends Vue {
  /**
   * Data types that should be visible in view as editable forms e.g. 'e-mail', 'password', ...
   * Data types are specified in application config under [ProfileView.myDataContentTypes] key, default value is empty array
   */
  @Prop({ type: Array, required: true })
  public readonly myDataContentTypes!: Array<string>

  @Prop({ type: Object, required: true })
  public readonly userData!: UserData
}

export default Default
