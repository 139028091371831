
import { Component, Vue } from 'vue-property-decorator'

import { IInputMaskService, InputMaskServiceKey } from '../services/inputmask'
import { InputField } from '../services/inputmask/config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'InputMaskMixin'
})
export class InputMaskMixin extends Vue {
  // NOTE: Regular @Inject reference is not working correctly despite $container presence
  private get inputMaskService (): IInputMaskService | undefined {
    if (!this.$container) {
      return
    }
    return this.$container.get<IInputMaskService>(InputMaskServiceKey)
  }

  public maskInputField (countryCode: string, field: InputField, element: HTMLElement | null): void {
    if (element && this.inputMaskService) {
      this.inputMaskService.maskInputValue(countryCode, field, element)
    }
  }
}
