




















































import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<Consents>({
  name: 'Consents',
  components: { VClamp: () => import(/* webpackChunkName: "vue-clamp" */ 'vue-clamp') },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()
  }
})
export class Consents extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: Array, required: false, default: [] })
  public readonly consents!: AnyObject[]

  @Prop({ type: Function, required: true })
  public readonly getErrors!: (name: string) => string[] | undefined

  @Prop({ type: String, required: false, default: 'shared.consents' })
  public readonly translationLabel!: string

  @Prop({ type: Boolean, required: false, default: true })
  public readonly hasRequiredText!: boolean

  @PropSync('payload', { type: Object, required: true })
  public _payload!: AnyObject

  public expandedGroup: string[] = []
  public pages: Record<string, string> | null = null

  public get checkAll (): boolean {
    return this.consents.every((key: AnyObject) => {
      return typeof this._payload[key.option] !== 'undefined' && this._payload[key.option]
    })
  }

  public set checkAll (checked: boolean) {
    const newConsents = this.consents.reduce((acc, consent) => ({
      ...acc,
      [consent.option]: checked
    }), {})

    this._payload = {
      ...this._payload,
      ...newConsents
    }
  }

  public get hasCheckAll (): boolean {
    return this.consents.length > 1
  }

  public isExpanded (key: string): boolean {
    return this.expandedGroup.includes(key)
  }

  public update (val: boolean, key: string) {
    this._payload = {
      ...this._payload,
      [key]: val
    }
  }

  public expand (key: string): void {
    if (this.isExpanded(key)) {
      this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
    } else {
      this.expandedGroup.push(key)
    }
  }
}

export default Consents
