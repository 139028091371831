






































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { AuthMixin, UserModel } from '../../../auth/shared'

import { Form } from '../../../shared/molecules/Form'
import { Loader } from '../../../shared/molecules/Loader'

import { Inject } from '../../../../support'
import { FormErrorsMixin } from '../../../../support/mixins'

import { IProfileService, ProfileServiceType } from '../../contracts'

import {
  ChangeNewsletterPreferencesFormContracts
} from './ChangeNewsletterPreferencesForm.contracts'
import {
  changeNewsletterPreferencesFormValidatorsMap
} from './ChangeNewsletterPreferencesForm.form'
import { UserMixin } from '../../shared'
import { ToastType } from '../../../shared/services'
import { ISiteService, SiteServiceType } from '../../../../contexts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ChangeNewsletterPreferencesForm>({
  name: 'ChangeNewsletterPreferencesForm',
  components: { Form, Loader },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()
  },
  created () {
    if (!this.isLoggedInUser) {
      return
    }
    this.loadNewsletterPreferences()
  }
})
export class ChangeNewsletterPreferencesForm extends Mixins(AuthMixin, FormErrorsMixin, UserMixin) {
  @Inject(ProfileServiceType)
  protected readonly profileRepository!: IProfileService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  /**
   * Validators map.
   */
  public readonly validatorsMap = changeNewsletterPreferencesFormValidatorsMap

  /**
   * Forms payload.
   */
  public formData: ChangeNewsletterPreferencesFormContracts = {
    isSubscribedNewsletter: false
  }

  public isLoading: boolean = true
  public pages: Record<string, string> | null = null

  public get privacyPolicyLink (): RawLocation {
    if (!this.pages) {
      return {
        path: ''
      }
    }

    return { path: this.pages.privacy }
  }

  public loadNewsletterPreferences () {
    this.formData.isSubscribedNewsletter = this.user?.isSubscribed ?? false
    this.isLoading = false
  }

  public async onChangeSubscribeNewsletterCheckbox (isSubscribedNewsletter: boolean) {
    try {
      await this.onSubmit(isSubscribedNewsletter)
    } catch (error) {
      this.setError(error as Error)
      this.formData.isSubscribedNewsletter = isSubscribedNewsletter
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Handles @fail event of form.
   */
  public onFail (error: Error): void {
    this.formData.isSubscribedNewsletter = !this.formData.isSubscribedNewsletter
    this.setError(error)
    this.isLoading = false
  }

  /**
   * Submits the form.
   */
  public async onSubmit (isSubscribedNewsletter: boolean): Promise<UserModel> {
    this.error = ''
    this.isLoading = true

    return await this.profileRepository.subscribeNewsletter(isSubscribedNewsletter)
  }

  public onSuccess (): void {
    this.isLoading = false
    this.showToast(
      this.$t('front._common.saved').toString(),
      ToastType.Success
    )
  }

  @Watch('isLoggedInUser')
  public onWaitingForAuth () {
    this.loadNewsletterPreferences()
  }
}

export default ChangeNewsletterPreferencesForm
