
























































import {
  Component,
  Prop,
  Watch,
  Inject as VueInject,
  Mixins,
  Ref,
  Vue
} from 'vue-property-decorator'
import { AnyObject, EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../../support'

import { IToastsService, ToastsServiceType, ToastType } from '../../../../shared/services'
import { Consents } from '../../../../shared/molecules/Consents/Consents.vue'
import { Loader } from '../../../../shared/molecules/Loader'
import { SimpleForm, ValidationRules } from '../../../../shared/molecules/Form'
import { SubscriptionsMixin, UserData } from '../../../../auth/shared'
import { InputMaskMixin } from '../../../../shared/mixins/inputmask.mixin'
import { InputField } from '../../../../shared/services/inputmask/config'

import { IProfileService, ProfileServiceType } from '../../../contracts'

import { DataEntityType, FormInputType } from './DataFormEntity.config'
import { DataFormEntityPayload } from './DataFormEntity.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProfileEditableForm>({
  name: 'ProfileEditableForm',
  components: {
    Consents,
    Loader,
    SimpleForm
  },
  created () {
    this.initFormStructure()
  },
  mounted () {
    /**
     * Update errors
     */
    this.eventBus.handle(
      'ui:user-data:errors',
      (event: EventPayload<Record<string, string[] | undefined>>) => {
        this.errors = event.payload ?? {}
      }
    )

    // if (this.shouldMaskPhone) {
    if (this.phoneRef) {
      for (const f of this.phoneRef) {
        if (f.$el) {
          this.maskInputField(
            'PL',
            InputField.Phone,
            f.$el?.querySelector('input')
          )
        }
      }
    }
  },
  beforeDestroy () {
    this.errors = {}
  }
})
export class ProfileEditableForm extends Mixins(SubscriptionsMixin, InputMaskMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(ToastsServiceType, false)
  private readonly toastService?: IToastsService

  @Prop({ type: Array, required: true })
  public readonly data!: DataFormEntityPayload[]

  @Prop({ type: String, required: true })
  public readonly heading!: string

  @Prop({ type: Object, required: true })
  public readonly user!: UserData

  @Prop({ type: Object, required: true })
  public readonly validatorsMap!: ValidationRules

  @Prop({ type: Function, required: true })
  public readonly submitCallback!: (formData: AnyObject) => Promise<void>

  @Ref('phoneRef')
  public phoneRef!: Vue[]

  /** Input entity type */
  public entityType = DataEntityType

  /** Alert error */
  public error: string | null = null
  /** Form errors */
  public errors: Record<string, string[] | undefined> = {}
  /** Validation error showed when passwords are not the same */
  public repeatErrors: string[] = []

  /** Form payload */
  public formData: AnyObject = {}
  /** Loading state */
  public isLoading: boolean = true
  /** Repeat password payload */
  public passwordRepeat: string = ''
  /** Check if new passwords are the same */
  public samePasswords: boolean = false

  public readonly consents = [
    {
      option: 'smsOffers',
      hasDescription: true,
      required: false
    }
  ]

  public get computedData (): DataFormEntityPayload[] {
    return this.data
  }

  public get computedError (): string | null {
    return this.error
  }

  public get hasSmsConsent (): boolean {
    return this.computedData[0].dataType === DataEntityType.Phone
  }

  public htmlType (type: string): string {
    switch (type) {
      case DataEntityType.Phone:
        return 'tel'
      case DataEntityType.Email:
        return 'email'
      default:
        return 'text'
    }
  }

  /**
   * Consider rendering proper DSL component
   * @param type
   */
  public inputComponent (type: string) {
    if (type === DataEntityType.Password || type === DataEntityType.NewPassword) {
      return FormInputType.Password
    } else {
      return FormInputType.Text
    }
  }

  public onSuccess () {
    /** Clear errors if any */
    this.errors = {}

    if (typeof this.toastService === 'undefined') {
      return
    }

    this.toastService.show(this.$t('front._common.saved').toString(), ToastType.Success)

    for (const key of Object.keys(this.formData)) {
      this.formData = {
        ...this.formData,
        [key]: ''
      }
    }
    this.passwordRepeat = ''
  }

  public onFail (errors: Error) {
    logger(errors, 'info')
    this.error = errors.message
  }

  public updateFormData (value: string, dataType: string) {
    this.formData = {
      ...this.formData,
      [dataType]: value
    }
  }

  public async changeUserData () {
    const types = []
    for (const value of Object.values(this.data)) {
      types.push(value.dataType)
    }

    let response

    this.isLoading = true

    try {
      response = await this.submitCallback(this.formData)

      this.$emit('onUpdate', this.formData)
      return response
    } catch (e) {
      logger((e as Error).message, 'error')
    } finally {
      this.isLoading = false
    }
  }

  public getErrors (key: string): string[] | undefined {
    if (!this.errors) {
      return
    }

    return this.errors[key]
  }

  /**
   * Init formData object & validators map
   * @protected
   */
  protected initFormStructure () {
    /** Init formData structure */
    for (const item of Object.values(this.data)) {
      const valueFromUser = Object.entries(this.user).find(([k, _v]) => item.dataType === k)

      this.formData = {
        ...this.formData,
        [item.dataType]: valueFromUser ? valueFromUser[1] : item.content
      }
    }

    /** Lock button only if password is found in form */
    this.samePasswords = !Object.values(this.data)
      .find(item => item.dataType === DataEntityType.NewPassword)

    this.isLoading = false
  }

  /**
   * Watch typing repeated password
   */
  @Watch('passwordRepeat')
  public checkPasswordsSimilarity () {
    for (const v of Object.values(this.computedData)) {
      if (v.dataType === DataEntityType.NewPassword) {
        if (this.formData[DataEntityType.NewPassword] !== this.passwordRepeat) {
          this.repeatErrors = []
          this.repeatErrors.push(String(this.$t(
            'front.profile.views.profile.form.errors.samePasswords')))
          this.samePasswords = false
        } else {
          this.repeatErrors = []
          this.samePasswords = true
        }
      }
    }
  }

  @Watch('userSubscriptions')
  private onUserSubscriptionsLoad () {
    if (this.hasSmsConsent) {
      this.formData = { ...this.formData, smsOffers: this.isSubscribed('sms') }
    }
  }
}

export default ProfileEditableForm
