















































import { Component, Inject } from 'vue-property-decorator'
import { AddressDataMixin } from '../../../../shared/mixins/address.mixin'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<AdditionalShipping>({
  name: 'AdditionalShipping'
})
export class AdditionalShipping extends AddressDataMixin {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean
}

export default AdditionalShipping
