

























import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { Loader } from '../../../../shared/molecules/Loader'
import { ToastMixin } from '../../../../shared'
import { ToastType } from '../../../../shared/services'
import { UserData } from '../../../../auth/shared'
import { UserRefreshMixin } from '../../../../shared/mixins/userRefresh.mixin'

import { ProfileWrapper } from '../../../molecules/ProfileWrapper'

import DataFormEntity from '../partials/DataFormEntity.vue'
import { DataEntityType } from '../partials/DataFormEntity.config'
import { DataContent, DataContentEntity } from '../ProfileView.config'
import ProfileEditableForm from '../partials/EditableForm.vue'
import { DataFormEntityPayload } from '../partials/DataFormEntity.contracts'
import { ValidationRules } from '../../../../shared/molecules/Form'
import { userProfileValidatorsRegistry } from '../partials/EditableForm.helpers'
import { AddressData } from '../../../../../contexts'
import { CustomerDetailsConfig } from '../../../../analytics/contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Simple>({
  name: 'Simple',
  components: {
    DataFormEntity,
    Loader,
    ProfileEditableForm,
    ProfileWrapper
  },
  created () {
    /**
     * @inheritDoc
     */
    this.initDataTypes()
  }
})
export class Simple extends Mixins(UserRefreshMixin, ToastMixin) {
  /**
   * Data types that should be visible in view as editable forms e.g. 'e-mail', 'password', ...
   * Data types are specified in application config under [ProfileView.myDataContentTypes] key, default value is empty array
   */
  @Prop({ type: Array, required: true })
  public readonly myDataContentTypes!: Array<string>

  @Prop({ type: Object, required: true })
  public readonly userData!: UserData

  public data: DataContent | null = null

  public defaultAddress: AddressData | null = null
  public currentForm: DataEntityType | null = null
  public selectedData!: DataFormEntityPayload[] | null

  public showForm: boolean = false

  public get dataContent (): DataContentEntity[] | undefined {
    if (!this.data) {
      return
    }

    return Object.values(Object.values(this.data)).sort((a, b) => a.order > b.order ? -1 : 1)
  }

  public get headline (): string {
    return this.showForm ? this.$t('front.profile.views.profile.myDataEditing').toString()
      : this.$t('front.profile.views.profile.myData').toString()
  }

  public get formHeading (): string {
    if (!this.data || !this.currentForm) {
      return ''
    }

    return this.data[this.currentForm].label
  }

  public get showFormValue (): boolean {
    return this.showForm
  }

  public get validationRules (): ValidationRules {
    let rules = {}

    this.selectedData?.forEach(field => {
      rules = { ...rules, ...userProfileValidatorsRegistry[field.dataType] }
    })

    return rules
  }

  public get submit () {
    if (this.currentForm === DataEntityType.Password) {
      return this.profileService.changePassword.bind(this.profileService)
    } else if (this.currentForm === DataEntityType.Name) {
      return this.profileService.changeUserDetails.bind(this.profileService)
    } else if (this.currentForm === DataEntityType.Phone) {
      return this.updatePhone.bind(this)
    }

    return () => Promise.reject(new Error('missing callback for form'))
  }

  /**
   * Request data change
   * @param dataKey
   * @param payload
   */
  public onChangeRequest (dataKey: DataEntityType, payload: DataFormEntityPayload[]) {
    this.selectedData = payload
    this.currentForm = dataKey
    this.showForm = true
  }

  public onCancel () {
    this.selectedData = null
    this.showForm = false
    this.currentForm = null
  }

  public onUpdate (formData: AnyObject) {
    /**
     * @inheritDoc
     */
    this.getUpdatedUserData()

    this.showToast(String(this.$t('front.profile.views.profile.form.success')), ToastType.Success)

    this.showForm = false

    this.track(formData)
  }

  protected track (formData: AnyObject) {
    let payload: CustomerDetailsConfig | undefined

    switch (this.currentForm) {
      case DataEntityType.Name:
        payload = {
          firstname: formData.firstName ?? '',
          lastname: formData.lastName ?? ''
        }
        break
      case DataEntityType.Phone:
        payload = {
          phone: formData.phoneNumber ?? '',
          smsOffers: formData.smsOffers
        }
        break
    }

    if (payload) {
      this.eventBus.emit('app:customer_details', payload)
    }
  }

  protected getDefaultAddress (userData: UserData): AddressData | null {
    return userData.addresses.find(address => address.defaultBilling) ?? null
  }

  protected updatePhone (payload: AnyObject): Promise<number> {
    if (!this.defaultAddress?.id) {
      return this.profileService.storeDeliveryAddress({
        company: '',
        countryCode: 'PL',
        city: '-',
        firstName: '-',
        lastName: '-',
        postalCode: '-',
        phoneNumber: '',
        street: '-',
        defaultBilling: true,
        defaultShipping: false,
        ...payload
      })
    }

    return this.profileService.updateDeliveryAddress(this.defaultAddress.id, {
      ...this.defaultAddress,
      ...payload
    })
  }

  /**
   * Compose data that should be visible in user profile
   * @protected
   */
  protected initDataTypes (useUserObject: boolean = false): void {
    if (!this.userData) {
      return
    }

    if (!this.user) {
      return
    }

    this.defaultAddress = this.getDefaultAddress(useUserObject ? this.userData : this.user)

    this.data = {
      email: {
        editable: false,
        key: DataEntityType.Email,
        label: this.$t('front.profile.views.profile.form.heading.email').toString(),
        order: 90,
        value: !useUserObject ? this.userData.email : this.user.email
      },
      phoneNumber: {
        editable: true,
        key: DataEntityType.Phone,
        label: this.$t('front.profile.views.profile.form.heading.phone').toString(),
        order: 91,
        value: this.defaultAddress?.phoneNumber ?? ''
      },
      name: {
        editable: true,
        key: DataEntityType.Name,
        label: this.$t('front.profile.views.profile.form.heading.name').toString(),
        order: 100,
        value: !useUserObject ? `${this.userData.firstName} ${this.userData.lastName}`
          : `${this.user.firstName} ${this.user.lastName}`
      },
      password: {
        editable: true,
        key: DataEntityType.Password,
        label: this.$t('front.profile.views.profile.form.heading.password').toString(),
        order: 80,
        value: '********'
      }
    }
  }

  @Watch('user')
  public onUserUpdate () {
    this.initDataTypes(true)
  }
}

export default Simple
