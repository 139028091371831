

































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'

import { Country, DeliveryAddressData } from '../../../../contexts'

import { Inject } from '../../../../support'
import { FormErrorsMixin } from '../../../../support/mixins'

import { AuthMixin, UserModel } from '../../../auth/shared'

import { DictionaryServiceType, IDictionaryService } from '../../../shared/services/dictionary'
import { Loader } from '../../../shared/molecules/Loader'
import { SimpleForm } from '../../../shared/molecules/Form'
import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'
import { UserMixin } from '../../shared'

import { ChangeAddressFormFields } from '../../molecules/ChangeAddressFormFields'
import { SavedAddressesFormFields } from '../../molecules/SavedAddressesFormFields'
import { IProfileService, ProfileServiceType } from '../../contracts'

import {
  changeAddressFormValidators,
  translateCountryToCountrySelectOption
} from './ChangeAddressForm.helpers'
import { DeliveryAddressFormData } from './ChangeAddressForm.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<ChangeAddressForm>({
  name: 'ChangeAddressForm',
  components: { ChangeAddressFormFields, Loader, SavedAddressesFormFields, SimpleForm },
  created (): void {
    this.loadUserData().then(() => {
      this.fetchDeliveryAddresses()
    })
  }
})
export class ChangeAddressForm extends Mixins(AuthMixin, FormErrorsMixin, UserMixin, ToastMixin) {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(DictionaryServiceType)
  protected readonly dictionaryService!: IDictionaryService

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  public deliveryAddresses: DeliveryAddressData[] = []

  public formData: DeliveryAddressFormData = {
    company: '',
    countryCode: '',
    city: '',
    firstName: '',
    lastName: '',
    postalCode: '',
    phoneNumber: '',
    street: ''
  }

  // public isEditable: boolean = false
  public editingAddressIndex: null | number = null

  public isLoading: boolean = true

  public readonly validatorsMap = changeAddressFormValidators

  /**
   * Gets all delivery addresses of the user.
   */
  public get allDeliveryAddress (): DeliveryAddressData[] {
    return this.deliveryAddresses
  }

  public get countries (): Array<Country> {
    return translateCountryToCountrySelectOption(this.dictionaryService.countries)
  }

  /**
   * Gets first delivery address of the user.
   */
  public get deliveryAddress (): DeliveryAddressData {
    return this.deliveryAddresses[0]
  }

  /**
   * Determines whether user has delivery addresses.
   */
  public get hasAddresses (): boolean {
    return Array.isArray(this.deliveryAddresses) && this.deliveryAddresses.length > 0
  }

  /**
   * Adds delivery address.
   */
  public addDeliveryAddress (): Promise<number> {
    this.isSubmitted = null
    this.error = ''

    if (!this.user || !this.user.addresses || this.user.addresses.length === 0) {
      return this.profileService.storeDeliveryAddress(this.formData)
    }

    if (!this.user.addresses[0].id) {
      throw new Error('Missing id of address')
    }

    if (this.editingAddressIndex !== null) {
      return this.profileService.updateDeliveryAddress(this.editingAddressIndex, this.formData)
    }

    return this.profileService.storeDeliveryAddress(this.formData)
  }

  public enableFields (): void {
    // this.isEditable = true
  }

  private updateAddress (address: any) {
    this.editingAddressIndex = address.id

    this.formData.firstName = address.firstName
    this.formData.lastName = address.lastName
    this.formData.company = address.company ? address.company : ''
    this.formData.city = address.city
    this.formData.postalCode = address.postalCode ? address.postalCode : ''
    this.formData.phoneNumber = address.phoneNumber
    this.formData.street = address.street
    this.formData.countryCode = address.countryCode
  }

  /**
   * Fetches delivery addresses
   */
  private fetchDeliveryAddresses (): void {
    this.editingAddressIndex = null
    try {
      if (!this.user || this.user.addresses.length === 0) {
        return
      }

      this.deliveryAddresses = { ...this.user.addresses }
    } catch (e) {
      this.setError(e as Error)
    } finally {
      this.isLoading = false
    }
  }

  public onSuccess (addressId: number): void {
    if (!this.user) {
      return
    }

    this.setUser({
      ...this.user,
      addresses: [{ ...this.formData, id: addressId }]
    })

    this.isSubmitted = true

    this.showToast(
      this.$t('front._common.saved').toString(),
      ToastType.Success
    )

    this.loadUserData().then(() => {
      this.fetchDeliveryAddresses()
    })
  }

  public onFail (e: Error): void {
    this.setError(e)
  }

  /**
   * Loads user data.
   * @protected
   */
  protected async loadUserData (): Promise<void> {
    try {
      if (!this.authService.token) {
        return
      }

      const userData = await this.profileService.getUserDetails(this.authService.token.accessToken)

      this.setUser(userData)
      this.authService.setUser(userData)
    } catch (e) {
      this.showToast((e as Error).message, 'danger')
    }
  }

  @Watch('hasAddresses')
  protected onDeliveryAddresses (value: boolean): void {
    if (value) {
      this.formData = {
        ...this.formData,
        ...this.deliveryAddress
      }
    }
  }

  @Watch('isLoggedInUser')
  protected async onWaitingForAuth () {
    this.fetchDeliveryAddresses()
  }
}

export default ChangeAddressForm
