

















import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProfileWrapper } from '../../../molecules/ProfileWrapper'

import { DataEntityType } from './DataFormEntity.config'
import { DataFormEntityPayload } from './DataFormEntity.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<DataFormEntity>({
  name: 'DataFormEntity',
  components: {
    ProfileWrapper
  }
})
export class DataFormEntity extends Vue {
  @Prop({ type: String, required: true })
  public readonly dataKey!: DataEntityType

  @Prop({ type: String, required: true })
  public readonly dataType!: string

  @Prop({ type: String, required: false, default: '' })
  public readonly dataContent!: string

  @Prop({ type: Boolean, required: false, default: true })
  public readonly editable?: boolean

  public get content () {
    if (this.dataType === DataEntityType.Password) {
      return this.$t('front.profile.views.profile.form.password').toString()
    }

    return this.dataContent
  }

  /**
   * Compose EditableForm data
   * @param dataType
   * @param content
   */
  public handleClick (dataType: DataEntityType, content: string) {
    let types
    let values: string[]

    /** Enter all DataEntityTypes that should inherit special modificator */
    const specialTypes = [DataEntityType.Password]

    switch (dataType) {
      case DataEntityType.Name:
        types = [DataEntityType.FirstName, DataEntityType.LastName]
        values = content.split(' ')
        break
      case DataEntityType.Password:
        types = [DataEntityType.Password, DataEntityType.NewPassword]
        values = content.split(' ')
        break
      default:
        types = [dataType]
        values = [content]
    }

    const payload: DataFormEntityPayload[] = types.map((fieldType, index) => ({
      dataType: fieldType,
      content: values[index],
      label: this.$t(`front.profile.views.profile.form.input.${fieldType}.label`).toString(),
      isSpecial: specialTypes.includes(fieldType as DataEntityType) ? '--special' : ''
    }))

    this.$emit('onChangeRequest', dataType, payload)
  }
}

export default DataFormEntity
